.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

h1 {
  padding-top: 60px;
  padding-bottom: 20px;
  font-family: "Roboto Condensed", sans-serif;
  color: #ee4325;
  text-transform: uppercase;
  font-size: 40px;
}

.pizza-answer {
  background-color: #ffd451;
  text-transform: uppercase;
  color: #ee4325;
  /* font-family: "Playwrite CU", cursive; */
  font-family: "Roboto Condensed", sans-serif;
  padding: 2px 20px;
}

.footer {
  background-image: url('https://t4.ftcdn.net/jpg/03/53/79/73/360_F_353797354_AgO2TjWFcDBhvaYtDl9IfdKJRFruSydS.jpg');
  background-repeat: repeat;
  height: 15vh;
  margin-top: 40px;
}

.appetite-label {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  padding-top: 8px;
}

.form-option {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
}

.pizza-diameter-separator {
  padding-top: 7px;
}